<template>
  <router-link
    :to="path"
    :class="{
      [buttonClass]: buttonClass,
      'current-event': currentEvent,
    }"
  >
    <span v-if="isPaid" class="mr-2">
      <i class="fas fa-credit-card"></i>
    </span>
    {{ title }}
  </router-link>
</template>

<script>
import { EVENT_TYPE, STREAM_STATUS } from '@/const';

export default {
  name: 'EventButton',
  props: {
    event: Object,
  },
  computed: {
    path() {
      if (this.event.has_access) {
        return { name: 'watch', params: { id: this.event.id } };
      }
      if (!this.event.has_access && this.event.type === EVENT_TYPE.register) {
        return { name: 'register' };
      }

      if (this.$route.query?.package_id) {
        return {
          name: 'buy',
          query: {
            event_id: this.event.id,
            single_event_package_id: this.$route.query?.package_id,
          },
        };
      }
      return {
        name: 'Select package',
        params: {
          id: this.event.id,
        },
      };
    },
    isPaid() {
      return (
        !this.event.has_access &&
        (this.event.type === EVENT_TYPE.paid ||
          this.event.type === EVENT_TYPE.ppv)
      );
    },
    title() {
      if (this.event.has_access) {
        return this.$t('WATCH');
      }
      if (!this.event.has_access && this.event.type === EVENT_TYPE.register) {
        return this.$t('REGISTER TO WATCH FREE');
      }
      if (this.isPaid && parseFloat(this.event.price)) {
        return this.$t('BUY FOR', { price: this.event.price });
      }
      if (this.isPaid) {
        return this.$t('BUY EVENT');
      }
      if (!this.$store.getters.isLogged) {
        return this.$t('Login');
      }
      return this.$t('BUY EVENT');
    },
    buttonClass() {
      if (this.event.has_access) {
        return 'watch-button';
      }
      if (!this.event.has_access && this.event.type === EVENT_TYPE.register) {
        return 'registration-button';
      }

      return 'buy-button';
    },
    currentEvent() {
      return this.event.stream_status === STREAM_STATUS.live;
    },
  },
};
</script>

<style lang="scss" scoped>
.watch-button {
  background: var(--settings-watch-button-background);
  color: var(--settings-watch-button-text-color);
  &:hover {
    color: var(--settings-watch-button-text-color);
  }
  &.current-event {
    background-color: var(--settings-watch-current-event-button-background);
    color: var(--settings-watch-current-event-button-text-color);
    &:hover {
      color: var(--settings-watch-current-event-button-text-color);
    }
  }
}
.registration-button {
  background-color: var(--settings-register-watch-button-background);
  color: var(--settings-register-watch-button-text-color);
  &:hover {
    color: var(--settings-register-watch-button-text-color);
  }
  &.current-event {
    background-color: var(
      --settings-registration-current-event-button-background
    );
    color: var(--settings-registration-current-event-button-text-color);
    &:hover {
      color: var(--settings-registration-current-event-button-text-color);
    }
  }
}
.buy-button {
  background-color: var(--settings-buy-button-background);
  color: var(--settings-buy-button-text-color);
  &:hover {
    color: var(--settings-buy-button-text-color);
  }
  &.current-event {
    background-color: var(--settings-buy-current-event-button-background);
    color: var(--settings-buy-current-event-button-text-color);
    &:hover {
      color: var(--settings-buy-current-event-button-text-color);
    }
  }
}
</style>
